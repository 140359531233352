import { useState } from 'react';
import './App.scss';
import Snowfall from 'react-snowfall'
import { Paper, TextField, Typography, Card, CardHeader, CardContent, CardActions, Button, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const errors = [
  'Nix', 'Nope', 'Nej', 'Njet', 'Wrong', 'Nei'
]
const pickError = () => {
  return errors[Math.floor(Math.random() * errors.length)];
}

function App() {
  const [index, setIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const input = [
    {
      header: "Første gåde - måske kender du den?",
      question: "Hvor mange liter sødmælk skal der til at lave risengrød af 1 kilogram grødris?",
      hints: [
        {
          header: "Hint 1",
          hint: "Jeg laver typisk risengrød af 400 gram ris"
        },
        {
          header: "Hint 2",
          hint: "Jeg bruger 1 liter til 200 gram ris"
        },
        {
          header: "Hint 3",
          hint: "5 liter - jeg havde stadig forventet mere af dig.."
        }
      ],
      answers: ['5', '5 liter', '5l']
    },
    {
      header: "Anden gåde. Nu til lidt historie.",
      question: "Hvad var 3. del af din husbonds bryllupsløfte?",
      hints: [
        {
          header: "Hint 1",
          hint: "Det var ikke at han ville lave pandekager til dig hver eneste dag"
        },
        {
          header: "Hint 2",
          hint: "Måske skulle I et sted hen?"
        },
        {
          header: "Hint 3",
          hint: "Et sted hvor der måske lugter af grøn ost?"
        }
      ],
      answers: ['månen', 'moon', 'Månen', 'vi skal til månen', 'til månen', 'flyve til månen', 'Vi skal til månen', 'Til månen', 'Flyve til månen']
    },
    {
      header: "Tredje gåde",
      question: "For at komme til månen skal man flyve - men kan mennesker flyve?",
      hints: [
        {
          header: "Hint 1",
          hint: "Måske hvis man hopper rigtig højt?"
        },
        {
          header: "Hint 2",
          hint: "Hvad nu hvis man slår en ordentlig prut?"
        },
        {
          header: "Hint 3",
          hint: "Man kan jo bare blive lettere end luften. Svaret er \"ja\". ;)"
        }
      ],
      answers: ['Ja', 'ja', 'jo']
    }
  ]

  const next = () => {
    setDisabled(true);
    if (index == 2) {
      setTimeout(() => {
        window.location.href = "https://airexperience.dk"
      }, 2000);
      return;
    }
    setIndex(index + 1);
    setTimeout(() => setDisabled(false), 1000)
  }

  return (
    <div id="base">
      <Snowfall count={600}/>
      <Comp input={input[index]} next={next} disabled={disabled}/>
    </div>
  );
}

const Comp = ({ input, next, disabled }) => {
  const [errorText, setErrorText] = useState();
  const answers = input.answers

  const [extended, setExtended] = useState((new Array(input.hints.length)).fill(false));

  console.log(extended)

  let timer;
  const updateResponse = (event) => {
    setErrorText()
    clearTimeout(timer);
    if (!event.target.value.length) return;
    timer = setTimeout(() => {
      console.log(event.target.value)
      if (!answers.includes(event.target.value)) return setErrorText(pickError())
      next();
    }, 2000);
  }

  if (disabled) return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Paper style={{ minWidth: 400, maxWidth: 600 }} variant="outlined">
          <Card>
            <center>
              <Typography as="h1" style={{ fontSize: 50 }}> Korrekt! </Typography>
            </center>
          </Card>
        </Paper>
    </div>
  );

  const setExtendedF = (index) => {
    const newExtended = (new Array(input.hints.length)).fill(false);
    newExtended[index] = !extended[index];
    setExtended(newExtended);
  }

  const onFocus = () => setExtended((new Array(input.hints.length)).fill(false));

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <div style={{ minWidth: 400, maxWidth: 400 }} >
      <Paper style={{ minWidth: 400, maxWidth: 400 }} variant="outlined">
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 30 }}>Julegave</Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              { input.header }
            </Typography>
            <Typography variant="body2">
              { input.question }
            </Typography>
          </CardContent>
        </Card>
      </Paper>
      { input.hints.map((hint, index) => <Hint extended={extended} index={index} hint={hint} setExtended={setExtendedF} />) }
      <Paper variant="outlined" style={{ minWidth: 400, maxWidth: 600 }}>
        <TextField onFocus={onFocus} onChange={updateResponse} error={!!errorText}
      helperText={errorText} id="filled-basic" label="Svar" variant="filled" style={{ width: '100%'}}/>
      </Paper>
      </div>
  </div>;
}

const Hint = ({ hint, extended, setExtended, index })=> {
  // const [extended, setExtended] = useState(false);Array.
  console.log(extended[index])
  return <Accordion onChange={() => setExtended(index)} expanded={extended[index]} key={hint.header} style={{ minWidth: 400, maxWidth: 600 }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>{hint.header}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
          {hint.hint}
      </Typography>
    </AccordionDetails>
  </Accordion>
}
export default App;
